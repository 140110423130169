import { createAction } from '@ngrx/store';
import { Group, PaginationParams, PaginationResponse } from '@neuralegion/api';

export const loadGroup = createAction('[Groups] LOAD_GROUP', (payload: { groupId: string }) => ({
  payload
}));

export const loadGroupSuccess = createAction(
  '[Groups] LOAD_GROUP_SUCCESS',
  (payload: { group: Group }) => ({
    payload,
    parentType: loadGroup.type
  })
);

export const loadGroupFail = createAction('[Groups] LOAD_GROUP_FAIL', (payload: string) => ({
  payload,
  parentType: loadGroup.type
}));

export const addGroup = createAction('[Groups] ADD_GROUP', (payload: { group: Group }) => ({
  payload
}));

export const addGroupSuccess = createAction(
  '[Groups] ADD_GROUP_SUCCESS',
  (payload: { group: Group }) => ({
    payload,
    parentType: addGroup.type
  })
);

export const addGroupFail = createAction('[Groups] ADD_GROUP_FAIL', (payload: string) => ({
  payload,
  parentType: addGroup.type
}));

export const updateGroup = createAction('[Groups] UPDATE_GROUP', (payload: { group: Group }) => ({
  payload
}));

export const updateGroupSuccess = createAction(
  '[Groups] UPDATE_GROUP_SUCCESS',
  (payload: { group: Group }) => ({
    payload,
    parentType: updateGroup.type
  })
);

export const updateGroupFail = createAction('[Groups] UPDATE_GROUP_FAIL', (payload: string) => ({
  payload,
  parentType: updateGroup.type
}));

export const removeGroup = createAction(
  '[Groups] REMOVE_GROUP',
  (payload: { groupId: string }) => ({ payload })
);

export const removeGroupSuccess = createAction(
  '[Groups] REMOVE_GROUP_SUCCESS',
  (payload: { groupId: string }) => ({
    payload,
    parentType: removeGroup.type
  })
);

export const removeGroupFail = createAction('[Groups] REMOVE_GROUP_FAIL', (payload: string) => ({
  payload,
  parentType: removeGroup.type
}));

export const loadGroups = createAction(
  '[Groups] LOAD_GROUPS',
  (payload: { params: PaginationParams }) => ({ payload })
);

export const loadGroupsSuccess = createAction(
  '[Groups] LOAD_GROUPS_SUCCESS',
  (payload: PaginationResponse<Group>) => ({ payload, parentType: loadGroups.type })
);

export const loadGroupsFail = createAction('[Groups] LOAD_GROUPS_FAIL', (payload: string) => ({
  payload,
  parentType: loadGroups.type
}));

export const addGroupMember = createAction(
  '[Groups] ADD_GROUP_MEMBER',
  (payload: { groupId: string; memberId: string }) => ({ payload })
);

export const addGroupMemberSuccess = createAction(
  '[Groups] ADD_GROUP_MEMBER_SUCCESS',
  (payload: { groupId: string; memberId: string }) => ({ payload, parentType: addGroupMember.type })
);

export const addGroupMemberFail = createAction(
  '[Groups] ADD_GROUP_MEMBER_FAIL',
  (payload: string) => ({
    payload,
    parentType: addGroupMember.type
  })
);

export const removeGroupMember = createAction(
  '[Groups] REMOVE_GROUP_MEMBER',
  (payload: { groupId: string; memberId: string }) => ({ payload })
);

export const removeGroupMemberSuccess = createAction(
  '[Groups] REMOVE_GROUP_MEMBER_SUCCESS',
  (payload: { groupId: string; memberId: string }) => ({
    payload,
    parentType: removeGroupMember.type
  })
);

export const removeGroupMemberFail = createAction(
  '[Groups] REMOVE_GROUP_MEMBER_FAIL',
  (payload: string) => ({
    payload,
    parentType: removeGroupMember.type
  })
);
