import { createAction } from '@ngrx/store';
import { Role } from '@neuralegion/api';

// TODO: replace with lookup API in ADM-3644
/**
 * @deprecated use paginated loadRoles instead
 */
export const loadRolesV1 = createAction('[Roles] LOAD_ROLES_V1');

/**
 * @deprecated use paginated loadRolesFail instead
 */
export const loadRolesV1Fail = createAction('[Roles] LOAD_ROLES_V1_FAIL', (payload: string) => ({
  payload,
  parentType: loadRolesV1.type
}));

/**
 * @deprecated use paginated loadRolesSuccess instead
 */
export const loadRolesV1Success = createAction(
  '[Roles] LOAD_ROLES_V1_SUCCESS',
  (payload: { roles: Role[] }) => ({
    payload,
    parentType: loadRolesV1.type
  })
);
