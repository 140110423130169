export enum OrganizationPermission {
  BUSINESS_LOGIC = 'businessLogic',
  DAST = 'dast',
  DEV_CENTRIC = 'devCentric',
  FUZZER = 'fuzzer',
  ONLY_AUTHORIZED_HOSTS = 'onlyAuthorizedHosts',
  ORG_API_KEYS = 'orgApiKeys',
  PROJECT_API_KEYS = 'projectApiKeys',
  PROJECT_REPEATERS = 'projectRepeaters',
  RBAC = 'rbac',
  REPEATERS = 'repeaters',
  REPORTS = 'reports',
  SNYK_APPS = 'snykApps',
  SSO = 'sso',
  RTC_BASED_AUTH_RECORDER = 'rtcBasedAuthRecorder',
  RDP_BASED_AUTH_RECORDER = 'rdpBasedAuthRecorder'
}

export type OrganizationPermissions = Record<OrganizationPermission, boolean>;
