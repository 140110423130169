import { Action, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Role, Scope } from '@neuralegion/api';
import { selectUserScopes } from '@neuralegion/auth-api';
import { RolesV1State, rolesV1EntityAdapter } from './roles-v1.state';
import { State } from './state';

const selectRolesV1State = createFeatureSelector<RolesV1State>('roles-v1');

/**
 * @deprecated use paginated selectRoles instead
 */
export const selectRolesV1 = createSelector<State, [RolesV1State], Role[]>(
  selectRolesV1State,
  rolesV1EntityAdapter.getSelectors().selectAll
);

export const selectAllowedRoleIds = createSelector<
  State,
  [Role[], ReadonlySet<Scope>],
  Set<string>
>(
  selectRolesV1,
  selectUserScopes,
  (roles: Role[], scopes: ReadonlySet<Scope>) =>
    new Set<string>(
      roles
        .filter((role: Role) => role.scopes.every((scope: Scope) => scopes.has(scope)))
        .map((role) => role.id)
    )
);

/**
 * @deprecated TODO: replace with lookup API in ADM-3644
 */
export const selectRolesMapV1 = createSelector<State, [Role[]], Map<string, Role>>(
  selectRolesV1,
  (roles: Role[]) => new Map<string, Role>(roles.map((role) => [role.id, role]))
);

/**
 * @deprecated TODO: replace with lookup API in ADM-3644
 */
export const selectRoleByIdV1 = (roleId: string): MemoizedSelector<State, Role> =>
  createSelector<State, [RolesV1State], Role>(
    selectRolesV1State,
    (state: RolesV1State) => state.entities[roleId]
  );

const selectRolesPendingActions = createSelector<State, [RolesV1State], Action[]>(
  selectRolesV1State,
  (state: RolesV1State) => state.pending
);

/**
 * @deprecated use paginated selectRolesPendingStatus instead
 */
export const selectRolesV1PendingStatus = createSelector<State, [Action[]], boolean>(
  selectRolesPendingActions,
  (actions: Action[]) => actions?.length > 0
);
