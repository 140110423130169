import { createAction } from '@ngrx/store';
import { Group } from '@neuralegion/api';

/**
 * @deprecated use paginated loadGroups instead
 */
export const loadGroupsV1 = createAction(
  '[Groups] LOAD_GROUPS_V1',
  (payload?: { force: boolean }) => ({
    payload: { force: payload?.force ?? true }
  })
);

export const loadGroupsV1Success = createAction(
  '[Groups] LOAD_GROUPS_V1_SUCCESS',
  (payload: { groups: Group[] }) => ({
    payload,
    parentType: loadGroupsV1.type
  })
);

export const loadGroupsV1Fail = createAction('[Groups] LOAD_GROUPS_V1_FAIL', (payload: string) => ({
  payload,
  parentType: loadGroupsV1.type
}));

export const loadGroupsV1Skipped = createAction('[Groups] LOAD_GROUPS_V1_SKIPPED', () => ({
  parentType: loadGroupsV1.type
}));
