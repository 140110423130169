import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Group, Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { GroupsService } from '../services';
import {
  loadGroupsV1,
  loadGroupsV1Fail,
  loadGroupsV1Skipped,
  loadGroupsV1Success
} from './groups-v1.actions';
import { selectGroupsV1 } from './groups-v1.selectors';

@Injectable()
export class GroupsV1Effects {
  public readonly loadGroupsV1$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroupsV1),
      withLatestFrom(
        this.store.select(selectScopePermission(Scope.GROUPS_READ)),
        this.store.select(selectGroupsV1).pipe(map((groups) => !!groups?.length))
      ),
      exhaustMap(
        ([action, groupsReadPermission, loaded]: [
          ReturnType<typeof loadGroupsV1>,
          boolean,
          boolean
        ]) => {
          if (loaded && !action.payload.force) {
            return of(loadGroupsV1Skipped());
          }

          return groupsReadPermission
            ? this.groupsService.loadGroupsV1().pipe(
                map((groups: Group[]) => loadGroupsV1Success({ groups })),
                catchError((err: HttpErrorResponse) => of(loadGroupsV1Fail(err.error)))
              )
            : of(loadGroupsV1Success({ groups: [] }));
        }
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly groupsService: GroupsService
  ) {}
}
