import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group, PaginationResponse } from '@neuralegion/api';

@Injectable()
export class GroupsService {
  constructor(private readonly http: HttpClient) {}

  public loadGroup(groupId: string): Observable<Group> {
    return this.http.get<Group>(`/api/v1/me/org/groups/${groupId}`);
  }

  public addGroup(group: Group): Observable<Group> {
    return this.http.post<Group>('/api/v1/me/org/groups', group);
  }

  public updateGroup(group: Group): Observable<Group> {
    return this.http.put<Group>(`/api/v1/me/org/groups/${group.id}`, group);
  }

  public removeGroup(groupId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/me/org/groups/${groupId}`);
  }

  /**
   * @deprecated Use `GroupsService.loadGroups` instead
   */
  public loadGroupsV1(): Observable<Group[]> {
    return this.http.get<Group[]>('/api/v1/me/org/groups');
  }

  public loadGroups(params: HttpParams | null): Observable<PaginationResponse<Group>> {
    return this.http.get<PaginationResponse<Group>>('/api/v2/me/org/groups', {
      ...(params ? { params } : {})
    });
  }

  public addGroupMember(groupId: string, memberId: string): Observable<void> {
    return this.http.post<void>(`/api/v1/me/org/groups/${groupId}/memberships`, {
      members: [memberId]
    });
  }

  public removeGroupMember(groupId: string, memberId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/me/org/groups/${groupId}/memberships`, {
      body: { members: [memberId] }
    });
  }
}
