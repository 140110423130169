import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import { loadRolesV1, loadRolesV1Fail, loadRolesV1Success } from './roles-v1.actions';
import { RolesV1State, rolesV1EntityAdapter, rolesV1InitialState } from './roles-v1.state';

export const rolesV1Reducer = createReducer<RolesV1State, Action>(
  rolesV1InitialState,
  on(
    loadRolesV1,
    (state: RolesV1State, action): RolesV1State => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadRolesV1Success,
    (state: RolesV1State, action): RolesV1State =>
      rolesV1EntityAdapter.setAll(action.payload.roles, cleanupPendingState(state, action))
  ),
  on(
    loadRolesV1Fail,
    (state: RolesV1State, action): RolesV1State =>
      cleanupPendingState(state, action, action.payload)
  )
);
