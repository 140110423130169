import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { type PaginationResponse, Role, Scope } from '@neuralegion/api';
import { selectOwnOrganizationId, selectScopePermission } from '@neuralegion/auth-api';
import { PaginationParamsSerializationService } from '@neuralegion/core';
import { RolesService } from '../services';
import {
  addRole,
  addRoleFail,
  addRoleSuccess,
  loadRoles,
  loadRolesFail,
  loadRolesSuccess,
  removeRole,
  removeRoleFail,
  removeRoleSuccess,
  updateRole,
  updateRoleFail,
  updateRoleSuccess
} from './roles.actions';

@Injectable()
export class RolesEffects {
  public readonly addRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addRole),
      withLatestFrom(this.store.select(selectOwnOrganizationId).pipe(filter(Boolean))),
      exhaustMap(([action, orgId]: [ReturnType<typeof addRole>, string]) =>
        this.rolesService.addRole(orgId, action.payload.role).pipe(
          map((role: Role) => addRoleSuccess({ role })),
          catchError((err: HttpErrorResponse) => of(addRoleFail(err.error)))
        )
      )
    )
  );

  public readonly loadRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRoles),
      withLatestFrom(
        this.store.select(selectOwnOrganizationId).pipe(filter(Boolean)),
        this.store.select(selectScopePermission(Scope.ROLES_READ))
      ),
      exhaustMap(
        ([action, orgId, rolesReadPermission]: [ReturnType<typeof loadRoles>, string, boolean]) =>
          rolesReadPermission
            ? this.rolesService
                .loadRoles(
                  orgId,
                  this.paginationParamsSerializationService.convertToHttpParams(
                    action.payload.params
                  )
                )
                .pipe(
                  map((res: PaginationResponse<Role>) => loadRolesSuccess(res)),
                  catchError((err: HttpErrorResponse) => of(loadRolesFail(err.error)))
                )
            : of(loadRolesSuccess({ items: [], total: 0 }))
      )
    )
  );

  public readonly removeRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeRole),
      withLatestFrom(this.store.select(selectOwnOrganizationId).pipe(filter(Boolean))),
      exhaustMap(([action, orgId]: [ReturnType<typeof removeRole>, string]) =>
        this.rolesService.removeRole(orgId, action.payload.roleId).pipe(
          map(() => removeRoleSuccess({ roleId: action.payload.roleId })),
          catchError((err: HttpErrorResponse) => of(removeRoleFail(err.error)))
        )
      )
    )
  );

  public readonly updateRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRole),
      withLatestFrom(this.store.select(selectOwnOrganizationId).pipe(filter(Boolean))),
      exhaustMap(([action, orgId]: [ReturnType<typeof updateRole>, string]) =>
        this.rolesService.updateRole(orgId, action.payload.role).pipe(
          map((role: Role) => updateRoleSuccess({ role })),
          catchError((err: HttpErrorResponse) => of(updateRoleFail(err.error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly rolesService: RolesService,
    private readonly paginationParamsSerializationService: PaginationParamsSerializationService
  ) {}
}
