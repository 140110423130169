import { createAction } from '@ngrx/store';
import { PaginationParams, PaginationResponse, Role } from '@neuralegion/api';

export const addRole = createAction('[Roles] ADD_ROLE', (payload: { role: Omit<Role, 'id'> }) => ({
  payload
}));

export const addRoleFail = createAction('[Roles] ADD_ROLE_FAIL', (payload: string) => ({
  payload,
  parentType: addRole.type
}));

export const addRoleSuccess = createAction(
  '[Roles] ADD_ROLE_SUCCESS',
  (payload: { role: Role }) => ({
    payload,
    parentType: addRole.type
  })
);

export const removeRole = createAction('[Roles] REMOVE_ROLE', (payload: { roleId: string }) => ({
  payload
}));

export const removeRoleFail = createAction('[Roles] REMOVE_ROLE_FAIL', (payload: string) => ({
  payload,
  parentType: removeRole.type
}));

export const removeRoleSuccess = createAction(
  '[Roles] REMOVE_ROLE_SUCCESS',
  (payload: { roleId: string }) => ({
    payload,
    parentType: removeRole.type
  })
);

export const loadRoles = createAction(
  '[Roles] LOAD_ROLES',
  (payload: { params: PaginationParams }) => ({ payload })
);

export const loadRolesSuccess = createAction(
  '[Roles] LOAD_ROLES_SUCCESS',
  (payload: PaginationResponse<Role>) => ({ payload, parentType: loadRoles.type })
);

export const loadRolesFail = createAction('[Roles] LOAD_ROLES_FAIL', (payload: string) => ({
  payload,
  parentType: loadRoles.type
}));

export const updateRole = createAction('[Roles] UPDATE_ROLE', (payload: { role: Role }) => ({
  payload
}));

export const updateRoleFail = createAction('[Roles] UPDATE_ROLE_FAIL', (payload: string) => ({
  payload,
  parentType: updateRole.type
}));

export const updateRoleSuccess = createAction(
  '[Roles] UPDATE_ROLE_SUCCESS',
  (payload: { role: Role }) => ({
    payload,
    parentType: updateRole.type
  })
);
