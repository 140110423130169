import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { Group } from '@neuralegion/api';
import { GroupsV1State, groupsV1EntityAdapter } from './groups-v1.state';
import { State } from './state';

export const selectGroupsV1State = createFeatureSelector<GroupsV1State>('groups-v1');

/**
 * @deprecated use paginated selectGroups instead
 */
export const selectGroupsV1 = createSelector<State, [GroupsV1State], Group[]>(
  selectGroupsV1State,
  groupsV1EntityAdapter.getSelectors().selectAll
);

const selectGroupsV1PendingActions = createSelector<State, [GroupsV1State], Action[]>(
  selectGroupsV1State,
  (state: GroupsV1State) => state.pending
);

/**
 * @deprecated use paginated selectGroupsPendingStatus instead
 */
export const selectGroupsV1PendingStatus = createSelector<State, [Action[]], boolean>(
  selectGroupsV1PendingActions,
  (actions: Action[]) => actions?.length > 0
);
