import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Group } from '@neuralegion/api';

export interface GroupsV1State extends EntityState<Group> {
  error: string;
  pending: Action[];
}

export const groupsV1EntityAdapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (model) => model.id
});

export const groupsV1InitialState: GroupsV1State = groupsV1EntityAdapter.getInitialState({
  error: null,
  pending: []
});
