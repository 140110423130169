import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  loadGroupsV1,
  loadGroupsV1Fail,
  loadGroupsV1Skipped,
  loadGroupsV1Success
} from './groups-v1.actions';
import { GroupsV1State, groupsV1EntityAdapter, groupsV1InitialState } from './groups-v1.state';

export const groupsV1Reducer = createReducer<GroupsV1State, Action>(
  groupsV1InitialState,
  on(
    loadGroupsV1,
    (state: GroupsV1State, action): GroupsV1State => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadGroupsV1Fail,
    (state: GroupsV1State, action): GroupsV1State =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(
    loadGroupsV1Success,
    (state: GroupsV1State, action): GroupsV1State =>
      groupsV1EntityAdapter.setAll(action.payload.groups, cleanupPendingState(state, action))
  ),
  on(
    loadGroupsV1Skipped,
    (state: GroupsV1State, action): GroupsV1State => cleanupPendingState(state, action)
  )
);
