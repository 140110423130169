import { createAction } from '@ngrx/store';
import { Invitation, Member, PaginationParams, PaginationResponse } from '@neuralegion/api';

export const loadMembers = createAction(
  '[OrganizationMembers] LOAD_MEMBERS',
  (payload: { params: PaginationParams }) => ({ payload })
);

export const loadMembersSuccess = createAction(
  '[OrganizationMembers] LOAD_MEMBERS_SUCCESS',
  (payload: PaginationResponse<Member>) => ({ payload, parentType: loadMembers.type })
);

export const loadMembersFail = createAction(
  '[OrganizationMembers] LOAD_MEMBERS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadMembers.type
  })
);

export const loadMember = createAction(
  '[OrganizationMembers] LOAD_MEMBER',
  (payload: { memberId: string }) => ({
    payload
  })
);

export const loadMemberSuccess = createAction(
  '[OrganizationMembers] LOAD_MEMBER_SUCCESS',
  (payload: Member) => ({
    payload,
    parentType: loadMember.type
  })
);

export const loadMemberFail = createAction(
  '[OrganizationMembers] LOAD_MEMBER_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadMember.type
  })
);

export const removeMember = createAction(
  '[OrganizationMembers] REMOVE_MEMBER',
  (payload: { memberId: string }) => ({
    payload
  })
);

export const removeMemberSuccess = createAction(
  '[OrganizationMembers] REMOVE_MEMBER_SUCCESS',
  (payload: { memberId: string }) => ({ payload, parentType: removeMember.type })
);

export const removeMemberFail = createAction(
  '[OrganizationMembers] REMOVE_MEMBER_FAIL',
  (payload: string) => ({
    payload,
    parentType: removeMember.type
  })
);

export const addMember = createAction(
  '[OrganizationMembers] ADD_MEMBER',
  (payload: { invitation: Invitation }) => ({
    payload
  })
);

export const addMemberSuccess = createAction('[OrganizationMembers] ADD_MEMBER_SUCCESS', () => ({
  parentType: addMember.type
}));

export const addMemberFail = createAction(
  '[OrganizationMembers] ADD_MEMBER_FAIL',
  (payload: string) => ({
    payload,
    parentType: addMember.type
  })
);

export const updateMember = createAction(
  '[OrganizationMembers] UPDATE_MEMBER',
  (payload: { member: Member & { groups?: string[] } }) => ({
    payload
  })
);

export const updateMemberSuccess = createAction(
  '[OrganizationMembers] UPDATE_MEMBER_SUCCESS',
  (payload: { member: Member & { groups?: string[] } }) => ({
    payload,
    parentType: updateMember.type
  })
);

export const updateMemberFail = createAction(
  '[OrganizationMembers] UPDATE_MEMBER_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateMember.type
  })
);

/**
 * @deprecated use paginated loadMembers instead
 */
export const loadMembersV1 = createAction('[OrganizationMembers] LOAD_MEMBERS_V1');

export const loadMembersV1Success = createAction(
  '[OrganizationMembers] LOAD_MEMBERS_V1_SUCCESS',
  (payload: Member[]) => ({
    payload,
    parentType: loadMembersV1.type
  })
);

export const loadMembersV1Fail = createAction(
  '[OrganizationMembers] LOAD_MEMBERS_V1_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadMembersV1.type
  })
);
