import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Role, Scope } from '@neuralegion/api';
import { selectOwnOrganizationId, selectScopePermission } from '@neuralegion/auth-api';
import { RolesService } from '../services';
import { loadRolesV1, loadRolesV1Fail, loadRolesV1Success } from './roles-v1.actions';

@Injectable()
export class RolesV1Effects {
  public readonly loadRolesV1$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRolesV1),
      withLatestFrom(
        this.store.select(selectOwnOrganizationId).pipe(filter(Boolean)),
        this.store.select(selectScopePermission(Scope.ROLES_READ))
      ),
      exhaustMap(([, orgId, rolesReadPermission]: [Action, string, boolean]) =>
        rolesReadPermission
          ? this.rolesService.loadRolesV1(orgId).pipe(
              map((roles: Role[]) => loadRolesV1Success({ roles })),
              catchError((err: HttpErrorResponse) => of(loadRolesV1Fail(err.error)))
            )
          : of(loadRolesV1Success({ roles: [] }))
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly rolesService: RolesService
  ) {}
}
