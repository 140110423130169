import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  addRole,
  addRoleFail,
  addRoleSuccess,
  loadRoles,
  loadRolesFail,
  loadRolesSuccess,
  removeRole,
  removeRoleFail,
  removeRoleSuccess,
  updateRole,
  updateRoleFail,
  updateRoleSuccess
} from './roles.actions';
import { RolesState, rolesEntityAdapter, rolesInitialState } from './roles.state';

export const rolesReducer = createReducer<RolesState, Action>(
  rolesInitialState,
  on(
    addRole,
    loadRoles,
    removeRole,
    updateRole,
    (state: RolesState, action): RolesState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    addRoleFail,
    loadRolesFail,
    removeRoleFail,
    updateRoleFail,
    (state: RolesState, action): RolesState => cleanupPendingState(state, action, action.payload)
  ),
  on(
    addRoleSuccess,
    (state: RolesState, action): RolesState =>
      rolesEntityAdapter.addOne(action.payload.role, cleanupPendingState(state, action))
  ),
  on(
    loadRolesSuccess,
    (state: RolesState, action): RolesState =>
      rolesEntityAdapter.setAll(action.payload.items, cleanupPendingState(state, action))
  ),
  on(
    removeRoleSuccess,
    (state: RolesState, action): RolesState =>
      rolesEntityAdapter.removeOne(action.payload.roleId, cleanupPendingState(state, action))
  ),
  on(
    updateRoleSuccess,
    (state: RolesState, action): RolesState =>
      rolesEntityAdapter.setOne(action.payload.role, cleanupPendingState(state, action))
  )
);
