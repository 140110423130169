import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { Role } from '@neuralegion/api';
import { RolesState, rolesEntityAdapter } from './roles.state';
import { State } from './state';

export const selectRolesState = createFeatureSelector<RolesState>('roles');

export const selectRoles = createSelector<State, [RolesState], Role[]>(
  selectRolesState,
  rolesEntityAdapter.getSelectors().selectAll
);

const selectRolesPendingActions = createSelector<State, [RolesState], Action[]>(
  selectRolesState,
  (state: RolesState) => state.pending
);

export const selectRolesPendingStatus = createSelector<State, [Action[]], boolean>(
  selectRolesPendingActions,
  (actions: Action[]) => actions?.length > 0
);
