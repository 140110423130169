import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Role } from '@neuralegion/api';

export interface RolesV1State extends EntityState<Role> {
  error: string;
  pending: Action[];
}

export const rolesV1EntityAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (model) => model.id
});

export const rolesV1InitialState: RolesV1State = rolesV1EntityAdapter.getInitialState({
  error: null,
  pending: []
});
